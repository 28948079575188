<template>
  <div class="detail">
    <div class="content">
      <div class="left">
        <div class="item">
          <span class="label">罚单编号：</span>
          <div>{{ info.penalShopNo }}</div>
        </div>
        <div class="item">
          <span class="label">处理店铺：</span>
          <div>{{ info.shop }}</div>
        </div>
        <div class="item">
          <span class="label">罚金理由：</span>
          <div>{{ info.reason }}</div>
        </div>
        <div class="item">
          <span class="label">罚金类型：</span>
          <div v-if="info.penalShopTypes && info.penalShopTypes.length">
            <TicketDetail :options="info.penalShopTypes" :total="info.penalSum"/>
          </div>
        </div>
        <div class="item">
          <span class="label">最晚处理时间：</span>
          <div>{{ info.estimateTime }}</div>
        </div>
        <div class="item">
          <span class="label">备注：</span>
          <div>{{ info.remark }}</div>
        </div>
        <div class="item">
          <span class="label">状态：</span>
          <div v-if="info.status" :style="{ color: colors[info.status] }">{{ $map.ticketStatus[info.status] }}</div>
        </div>
        <div class="item">
          <span class="label">照片/视频：</span>
          <div v-if="info.resource">
            <resources :source="info.resource" :multiple-preview="true"></resources>
          </div>
        </div>
      </div>
      <div class="right">
        <div>工单流程</div>
        <OrderFlow :repairNodes="orderFlow"/>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" @click="actionFun(0)" v-if="info.status === 1 && isVerify">核验</el-button>
      <el-button type="primary" @click="pressFun" v-if="info.status === 1 && !isVerify">催办</el-button>
      <el-button type="danger" @click="actionFun(1)" v-if="info.status === 1 && !isVerify">撤销</el-button>
      <el-button @click="backFun">返回</el-button>
    </div>
    <CheckModal :isShow.sync="isShow" :title="title" :type="type" :id="id" @callback="callback"/>
  </div>
</template>

<script>
import TicketDetail from '../components/ticket-detail.vue'
import OrderFlow from '@/pages/propertyModule/components/order-flow.vue'
import Resources from "@/pages/propertyModule/components/resources";
import CheckModal from '../components/check-modal.vue'
import reconava from '@/utils/tool.js'
import { urgeTicketApi } from '../../../../services/merchant'
const pressFunApi = reconava.debounce(urgeTicketApi, 15*60*1000)
const colors = {
  1: 'red',
  2: '#5b69fe',
  3: '#555555'
}
export default {
  name: 'Detail',
  components: { TicketDetail, OrderFlow, Resources, CheckModal },
  data () {
    return {
      id: this.$route.query.id || '',
      isVerify: this.$route.path === '/businessModule/merchant/verify/detail',
      colors: colors,
      type: 0, 
      title: '',
      info: {},
      orderFlow: [],
      isShow: false,
    }
  },
  created () {
    this.init()
  },
  methods: {
    actionFun (n) {
      this.type = n
      this.title = n ? '撤销理由' : '核验理由'
      this.isShow = true
    },
    pressFun () {
      pressFunApi({ id: this.id }).then(({ code, msg }) => {
        if(code !== 200) return this.$message.warning(msg)
        this.$message.success(msg)
      }).catch(() => {
        this.$message.warning('催单过于频繁，稍后操作')
      })
    },
    backFun () {
      this.$router.back()
    },
    init () {
      this.getDetial()
      this.getFlowDetail()
    },
    callback () {
      this.$router.back()
    },
    getDetial () {
      this.$api.merchant.detailTicketApi({ id: this.id }).then(({ code, data, msg }) => {
        if(code !== 200) return this.$message.warning(msg)
        this.info = data
      })
    },
    getFlowDetail () {
      this.$api.merchant.flowDetailTicketApi({ id: this.id }).then(({ code, data }) => {
        if(code !== 200) return this.$message.warning(msg)
        this.orderFlow = data
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.detail {
  .content {
    flex: 1;
    height: calc(100% - 73px);
    display: flex;
    .left {
      margin-right: 10px;
      flex: 1;
      padding: 10px 20px;
      background: #ffffff;
      height: 100%;
      overflow-y: auto;
      .item {
        display: flex;
        padding: 10px 0;
        > div {
          flex: 1;
        }
        > span {
          width: 120px;
          flex-shrink: 0;
          text-align: right;
        }
        .img_box > img {
          margin: 10px 10px 10px 0;
        }
      }
    }
    .right {
      height: 100%;
      overflow-y: auto;
      flex: 1;
      padding: 10px 20px;
      background-color: #ffffff;
    }
  }
  .footer {
    box-sizing: border-box;
    height: 73px;
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid #d7d7d7;
    text-align: right;
    .el-button {
      margin: 0 10px;
    }
  };
}
::v-deep.el-button--mini,.el-button--small{
  padding: 10px 18px;
  font-size: 14px;
}
</style>